import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Button, IconInfoCircle } from 'hds-react';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/dialog/customisation",
  "title": "Dialog - Customisation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component. See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--accent-line-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of the dialog accent line`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--overlay-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of the dialog overlay`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const dialogTargetRef = React.useRef(null); // We need to render the dialog into a div inside the Playground component to ensure correct dialog styles in the HDS documentation. The dialog will be rendered into the document body by default.
  const openInfoDialogButtonRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const titleId = 'info-dialog-title';
  const descriptionId = 'info-dialog-content';
  return (
    <>
      <div ref={dialogTargetRef} />
      <Button ref={openInfoDialogButtonRef} onClick={() => setIsOpen(true)}>
        Open Info dialog
      </Button>
      <Dialog
        id="info-dialog"
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
        isOpen={isOpen}
        close={close}
        closeButtonLabelText="Close info dialog"
        focusAfterCloseRef={openInfoDialogButtonRef}
        targetElement={dialogTargetRef.current}
        theme={{
          '--accent-line-color': 'var(--color-tram)',
          '--overlay-color': 'rgba(0, 0, 0, 0.7)'
        }}
      >
        <Dialog.Header
          id={titleId}
          title="Terms of service have changed"
          iconLeft={<IconInfoCircle aria-hidden="true" />}
        />
        <Dialog.Content>
          <p id={descriptionId} className="text-body">
            Please note that the terms of this service have changed. You can review the changes in the user settings.
          </p>
        </Dialog.Content>
        <Dialog.ActionButtons>
          <Button onClick={close}>Close</Button>
        </Dialog.ActionButtons>
      </Dialog>
    </>
  );
}}
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      